import { Icon } from "../Icons/Icon";
import { Typography } from "../Typography/Typography";
import clsx from "classnames";

import { useEffect, useState } from "react";

import "./styles.scss";

export interface SwitchProps {
    className?: string;
    label?: string;
    onToggle?: (isChecked: boolean) => void;
    isOn?: boolean;
    disabled?: boolean;
}

export function Switch({ className = "", label, onToggle, isOn, disabled }: SwitchProps) {
    const [isChecked, setIsChecked] = useState(isOn || false);

    useEffect(() => {
        setIsChecked(!!isOn);
    }, [isOn]);

    const handleToggle = () => {
        if (disabled) return;
        const newValue = !isChecked;
        setIsChecked(newValue);
        onToggle?.(newValue);
    };

    return (
        <label className={clsx(`switch-container ${className}`, { disabled: disabled })} data-testid={"switch"}>
            <input type='checkbox' checked={isChecked} onChange={handleToggle} />
            <div className='button'>
                <Icon name='check-bold' className='icon' size='sm' />
                <Icon name='x-bold' className='icon' size='sm' />
            </div>
            {label && <Typography message={label} />}
        </label>
    );
}
