import UneligibleImage from "src/shared/images/loan/uneligible.svg";
import { UserModel } from "src/shared/models/User";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Drawer } from "src/shared/atoms/Drawer/Drawer";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { List } from "src/shared/components/ExpandableList/ExpandableList";

import "./styles.scss";

type UserSelectionDrawerProps = {
    isOpen: boolean;
    onClose: () => void;
    handleSelectedUser: (user: UserModel) => void;
    handleOpenCreateUser: () => void;
    userList: UserModel[];
};

export function UserSelectionDrawer({
    isOpen,
    onClose,
    handleSelectedUser,
    handleOpenCreateUser,
    userList,
}: UserSelectionDrawerProps) {
    const { t } = useTranslation();
    const [research, setResearch] = useState("");

    useEffect(() => {
        setResearch("");
    }, [isOpen]);

    const filteredUsers = Object.values(userList).filter(
        (user) =>
            user.firstname.toLowerCase().indexOf(research.toLowerCase()) !== -1 ||
            user.lastname.toLowerCase().indexOf(research.toLowerCase()) !== -1
    );

    const handleResearch = (value: string) => {
        setResearch(value);
    };

    const selectUser = (user: UserModel) => () => {
        handleSelectedUser(user);
    };

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            header={{
                title: t("CommonUse.choose-user"),
                image: "user-circle",
            }}
            body={
                <div className='debit-drawer-body-container'>
                    <Input
                        placeholder={t("CommonUse.search")}
                        inputIcon='magnifying-glass'
                        onChange={handleResearch}
                        value={research}
                        className='search-input'
                    />
                    {!filteredUsers.length && research && (
                        <div className='error-message-no-wallets-available'>
                            <div
                                className='uneligible-image'
                                style={{ backgroundImage: `url(${UneligibleImage})` }}
                            ></div>
                            <Typography message={t("UserSelectionDrawer.no-user-available")} />
                        </div>
                    )}
                    <div className='create-user-section'>
                        <div className='title-section'>
                            <Avatar icon='information-circle' color='lilas-900' backgroundColor='lilas-50' />
                            <div className='text'>
                                <Typography
                                    className='fw-bold'
                                    message={t("UserSelectionDrawer.user-not-registered")}
                                />
                                <Typography message={t("UserSelectionDrawer.create-new-one")} />
                            </div>
                        </div>
                        <div className='content-section'>
                            <Button
                                onClick={handleOpenCreateUser}
                                label={t("UserSelectionDrawer.add-new-user")}
                                icon='user-plus'
                            />
                        </div>
                    </div>
                    <List
                        keyName={"users"}
                        extended
                        animation='fadeInUp'
                        items={filteredUsers.map((user) => {
                            return (
                                <div className={`item-container`} onClick={selectUser(user)}>
                                    <div className='left-content'>
                                        <Avatar
                                            size='sm'
                                            avatar={user.avatar ?? undefined}
                                            firstname={user.firstname}
                                            lastname={user.lastname}
                                        />
                                        <div className='item-content'>
                                            <Typography
                                                className='fw-bold'
                                                message={user.firstname + " " + user.lastname}
                                            />
                                        </div>
                                    </div>
                                    <Icon className='item-icon' size='md' name={"chevron-right"} />
                                </div>
                            );
                        })}
                    />
                </div>
            }
        />
    );
}
