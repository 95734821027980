import { BeneficiaryModel } from "src/shared/models/Beneficiary";
import { validateField } from "src/shared/utils/validateField";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useDebounce } from "src/shared/hooks/useDebounce";

import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type InformationsKeys = "email" | "firstname" | "lastname" | "label";
type InformationsForm = Pick<BeneficiaryModel, InformationsKeys>;
export type InformationsParams = InformationsForm;

type CreateBeneficiaryInformationFormProps = {
    isFormValidated: boolean;
    setIsFormValidated: (value: boolean) => void;
    setInformations: (value: InformationsParams) => void;
    editInformations: BeneficiaryModel | null;
    hasBankWire?: boolean;
};

export const CreateBeneficiaryInformationForm = ({
    isFormValidated,
    setIsFormValidated,
    setInformations,
    editInformations,
    hasBankWire,
}: CreateBeneficiaryInformationFormProps) => {
    const { t } = useTranslation();

    const [fields, setFields] = useState<InformationsParams>({
        email: editInformations?.email ?? "",
        firstname: editInformations?.firstname ?? "",
        lastname: editInformations?.lastname ?? "",
        label: editInformations?.label ?? "",
    });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const fieldValidationTypes: Record<keyof InformationsParams, "email" | "common" | "text"> = {
        email: "email",
        firstname: hasBankWire ? "common" : "text",
        lastname: hasBankWire ? "common" : "text",
        label: "text",
    };

    useEffect(() => {
        handleChangeInput("firstname")(fields.firstname);
        handleChangeInput("lastname")(fields.lastname);
    }, [hasBankWire]);

    const handleChangeInput = (name: keyof InformationsParams) => (value: string) => {
        setFields((prev) => ({ ...prev, [name]: value }));

        if (name !== "email") {
            const validationType = fieldValidationTypes[name];
            const { isValid, errorKey } = validateField(validationType, value);
            setErrors((prev) => {
                if (!isValid && errorKey) {
                    return { ...prev, [name]: t<string>(errorKey) };
                } else {
                    const { [name]: _, ...rest } = prev;
                    return rest;
                }
            });
        }
    };

    const debouncedEmail = useDebounce(fields.email, 700);

    useEffect(() => {
        const trimmedEmail = debouncedEmail.trim();
        if (trimmedEmail.length > 0 && !validateField("email", trimmedEmail).isValid) {
            setErrors((prev) => ({ ...prev, email: t("CommonUse.invalid-email") }));
        } else {
            setErrors((prev) => {
                const { email, ...rest } = prev;
                return rest;
            });
        }
    }, [debouncedEmail, t]);

    const handleBlur = (name: keyof InformationsParams) => () => {
        if (name === "email") {
            const trimmedEmail = fields.email.trim();
            if (trimmedEmail.length > 0 && !validateField("email", trimmedEmail).isValid) {
                setErrors((prev) => ({ ...prev, email: t("CommonUse.invalid-email") }));
            } else {
                setErrors((prev) => {
                    const { email, ...rest } = prev;
                    return rest;
                });
            }
        }
    };

    useEffect(() => {
        const isFormValid =
            fields.firstname.trim().length > 0 && fields.lastname.trim().length > 0 && Object.keys(errors).length === 0;
        setIsFormValidated(isFormValid);
        setInformations(fields);
    }, [fields, errors, setInformations, setIsFormValidated]);

    return (
        <div className='drawer-content-section'>
            <div className='drawer-content-section-header'>
                <Typography
                    className='fw-bold'
                    message={t("CreateBeneficiaryInformationForm.informations-sur-le-beneficiaire")}
                />
                <Typography
                    className={`${!isFormValidated ? "color-warning" : "color-success"} fw-bold`}
                    message={!isFormValidated ? t("CommonUse.to-complete") : t("CommonUse.done")}
                />
            </div>
            <div className='beneficiary-informations-content drawer-content-section-body'>
                <div className='form-part'>
                    <div className='inline-inputs'>
                        <Input
                            autoFocus
                            label={t("CreateBeneficiaryInformationForm.prenom-du-beneficiaire").toString()}
                            name='firstname'
                            value={fields.firstname}
                            placeholder={t("CreateBeneficiaryInformationForm.prenom")}
                            onChange={handleChangeInput("firstname")}
                            variant={errors.firstname ? "error" : "primary"}
                            underMessage={errors.firstname}
                        />
                        <Input
                            label={t("CreateBeneficiaryInformationForm.nom-du-beneficiaire").toString()}
                            name='lastname'
                            value={fields.lastname}
                            placeholder={t("CreateBeneficiaryInformationForm.nom")}
                            onChange={handleChangeInput("lastname")}
                            variant={errors.lastname ? "error" : "primary"}
                            underMessage={errors.lastname}
                        />
                    </div>
                    <Input
                        label={t("CreateBeneficiaryInformationForm.adresse-mail-facultatif").toString()}
                        name='email'
                        value={fields.email}
                        placeholder={t("CommonUse.email-exemple")}
                        onChange={handleChangeInput("email")}
                        onBlur={handleBlur("email")}
                        variant={errors.email ? "error" : "primary"}
                        underMessage={errors.email}
                    />
                    <Input
                        label={t("Beneficiaries.add-note").toString()}
                        name='label'
                        value={fields.label}
                        placeholder={t("Beneficiaries.add-note-placeholder")}
                        onChange={handleChangeInput("label")}
                        variant={errors.label ? "error" : "primary"}
                        underMessage={errors.label}
                    />
                </div>
            </div>
        </div>
    );
};
