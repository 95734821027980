import { EditTaskInputCell } from "./InputCell";
import { TaskItemTmpModel } from "src/shared/models/TaskItemTmp";
import { removeExtraSpaces } from "src/shared/utils/removeExtraSpaces";

import { handleUpdateTaskItem } from "src/modules/tasksItems/thunkActions";
import { useAppDispatch } from "src/store";

import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    taskId?: number;
    taskItem: TaskItemTmpModel;
    loading: boolean;
};

export function TasksItemEditFieldExternalName({ taskItem, taskId, loading }: Props) {
    const dispatch = useAppDispatch();

    const handleUpdateExternalFullname = (newExternalFullname: string) => {
        if (taskId && newExternalFullname !== taskItem.externalFullname) {
            dispatch(
                handleUpdateTaskItem({
                    taskId,
                    taskItem: taskItem,
                    update: {
                        externalFullname: removeExtraSpaces(newExternalFullname),
                    },
                })
            );
        }
    };
    return (
        <EditTaskInputCell
            disabled={loading}
            initialValue={taskItem.externalFullname ?? ""}
            onValidate={handleUpdateExternalFullname}
            type='string'
            externalReference={{ isExternalReference: true, isBankWire: taskItem.typeSlug === "bank-transfer" }}
        >
            <Typography message={taskItem.externalFullname ?? ""} className='edit-task-table-cell-content' />
        </EditTaskInputCell>
    );
}
