const senegalRegex = /^(221(77|78|76|70|75)|(77|78|76|70|75))[0-9]{7}$/;
const ivoryCoastRegex = /^((225(07|05|01|46))|(07|05|01|46))[0-9]{8}$/;
const beninRegex =
    /^(229(40|41|42|43|44|45|46|50|51|52|53|54|55|56|57|58|59|60|61|62|63|64|65|66|67|68|69|90|91|94|95|96|97|98|99)|(40|41|42|43|44|45|46|50|51|52|53|54|55|56|57|58|59|60|61|62|63|64|65|66|67|68|69|90|91|94|95|96|97|98|99))[0-9]{6}$/;
const togoRegex = /^(228)?(70|71|79|90|91|92|93|94|95|96|97|98|99)[0-9]{6}$/;

const isValidSenegalPhone = (phone: string) => senegalRegex.test(phone);
const isValidIvoryCoastPhone = (phone: string) => ivoryCoastRegex.test(phone);
const isValidBeninPhone = (phone: string) => beninRegex.test(phone);
const isValidTogoPhone = (phone: string) => togoRegex.test(phone);

export const isValidPhone = (phone: string) => {
    return (
        isValidSenegalPhone(phone) ||
        isValidIvoryCoastPhone(phone) ||
        isValidBeninPhone(phone) ||
        isValidTogoPhone(phone)
    );
};
