import { BillingPayBillFilters } from "../PaymentList/Filters";
import { BillingTopBillers } from "./BillingTopBillers";
import { BillingTopBillersFavoritesCarousel } from "./Favorites/Carousel";
import { BillingTopBillersSectors } from "./Sectors";
import { useLocation, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getUserCountryTag } from "src/modules/auth/selectors";
import { getBillingBillersQueryFilter } from "src/modules/billing/billers/selectors";
import { resetBillingBillerFilters, setBillingBillerQueryFilter } from "src/modules/billing/billers/slice";
import { fetchBillingBillers } from "src/modules/billing/billers/thunkActions";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "src/store";

import { Input } from "src/shared/atoms/Inputs/Input";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";

import "./styles.scss";

type LocationState = {
    preselectedWalletId?: string;
};

export const BillingTopBillersPanel = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [firstRender, setFirstRender] = useState(true);
    const userCountryCode = useAppSelector((state) => getUserCountryTag(state));

    const query = useAppSelector((state) => getBillingBillersQueryFilter(state));

    const providedState = useLocation().state as LocationState;

    const debouncedQuery = useDebounce(query, 1000);

    useEffect(() => {
        if (firstRender) {
            dispatch(resetBillingBillerFilters());
            dispatch(
                fetchBillingBillers({
                    page: 1,
                    categoryFilter: "direct-billers",
                })
            );
            setFirstRender(false);
        } else if (debouncedQuery?.length) {
            navigate("/billing/pay-bill-list");
        }
    }, [dispatch, debouncedQuery]);

    const handleSetQueryFilter = (value: string) => {
        dispatch(setBillingBillerQueryFilter({ query: value }));
    };

    return (
        <div className='top-billers-container'>
            <PageHeader>
                <div className='top-billers-filters'>
                    <Input
                        placeholder={t("CommonUse.search")}
                        inputIcon='magnifying-glass'
                        onChange={handleSetQueryFilter}
                        value={query ?? ""}
                        className='search-input'
                    />
                    <BillingPayBillFilters showTopBillerFilter={false} />
                </div>
            </PageHeader>
            <div className='top-biller-content'>
                {userCountryCode !== "TGO" && (
                    <BillingTopBillers preselectedWalletId={providedState?.preselectedWalletId} />
                )}
                <BillingTopBillersFavoritesCarousel preselectedWalletId={providedState?.preselectedWalletId} />
                <BillingTopBillersSectors preselectedWalletId={providedState?.preselectedWalletId} />
            </div>
        </div>
    );
};
