export type Country = {
    countryName: string;
    countryCode: string;
    phonePrefix: string;
    prefixLabel: string;
    flag: any;
};

export const countriesPhonePrefixes: Country[] = [
    {
        countryName: "Cote d'Ivoire",
        countryCode: "ci",
        phonePrefix: "00225",
        prefixLabel: "+225",
        flag: require("src/shared/images/flags/CI.png"),
    },
    {
        countryName: "Sénégal",
        countryCode: "sm",
        phonePrefix: "00221",
        prefixLabel: "+221",
        flag: require("src/shared/images/flags/SN.png"),
    },
    {
        countryName: "Bénin",
        countryCode: "bj",
        phonePrefix: "00229",
        prefixLabel: "+229",
        flag: require("src/shared/images/flags/BJ.png"),
    },
    {
        countryName: "Togo",
        countryCode: "tg",
        phonePrefix: "00228",
        prefixLabel: "+228",
        flag: require("src/shared/images/flags/TG.png"),
    },
];

export const billingCountryLabel: { [key: string]: string } = {
    "00225": "Countries.ivory-coast",
    "00221": "Countries.senegal",
    "00229": "Countries.benin",
    "00228": "Countries.togo",
};

export type BankData = {
    bankName: string;
    bic: string;
    currency: string;
    addSpacesToIban: number[];
};

export const countryCodeBank: { [countryCode: string]: BankData } = {
    CI: {
        bankName: "Countries.bank-ivory-coast",
        bic: "BDAJCIAB",
        currency: "XOF",
        addSpacesToIban: [4, 9, 14, 26],
    },
    SN: { bankName: "Countries.bank-senegal", bic: "BDKRSNDA", currency: "XOF", addSpacesToIban: [4, 9, 14, 26] },
    BN: { bankName: "Countries.bank-benin", bic: "", currency: "XOF", addSpacesToIban: [4, 9, 14, 26] },
    TG: { bankName: "Countries.bank-togo", bic: "", currency: "XOF", addSpacesToIban: [4, 9, 14, 26] },
};
