import { AdminUserCreateDrawerSectionsCollector } from "./Sections/Collector";
import { AdminUserCreateDrawerSectionsPhone } from "./Sections/Phone";
import { AdminUserCreateDrawerSectionsProfile } from "./Sections/Profile";
import { AdminUserCreateDrawerSectionsRole } from "./Sections/Role";
import { CreateUserContext } from "src/shared/context/createUser";

import { useContext } from "react";

import { getUserDetail } from "src/modules/admin/userDetail/selectors";
import { getUserCountryTag } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

import { Spinner } from "src/shared/atoms/Spinner/Spinner";

import "./styles.scss";

export function UserCreateDrawerBody() {
    const { step } = useContext(CreateUserContext);
    const { loading } = useAppSelector(getUserDetail);
    const userCountryCode = useAppSelector((state) => getUserCountryTag(state));

    return (
        <div className='create-user-drawer-body'>
            <AdminUserCreateDrawerSectionsPhone />

            {loading ? (
                <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
                    <Spinner size='lg' />
                </div>
            ) : (
                <>
                    {step > 0 && <AdminUserCreateDrawerSectionsProfile />}

                    {step > 1 && (
                        <>
                            <AdminUserCreateDrawerSectionsRole />
                            {userCountryCode !== "TGO" && <AdminUserCreateDrawerSectionsCollector />}
                        </>
                    )}
                </>
            )}
        </div>
    );
}
