import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { LoanPanel } from "src/components/Loan/Panel";

const LoanPageUnauthorized = () => {
    return <LoanPanel />;
};

export const LoanPage = withAuthorization({
    allowedRoles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY, CompanyRole.USER, CompanyRole.READ],
    disallowedCountries: ["BEN", "TGO"],
})(LoanPageUnauthorized);
