import { AdminAccountDetailDrawerArchiveUser } from "./Sections/ArchiveUser";
import { AdminUsersDetailDrawerSectionCollector } from "./Sections/Collector";
import { AdminAccountDetailDrawerDisableUser } from "./Sections/DisableUser";
import { AdminAccountDrawerPersonalInfo } from "./Sections/PersonalInfo";
import { AdminAccountDetailDrawerSectionsPhone } from "./Sections/Phone";
import { AdminAccountDetailDrawerSectionsRole } from "./Sections/Role";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { getUserDetail } from "src/modules/admin/userDetail/selectors";
import { getUserCountryTag } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";

import "./styles.scss";

export const UserDetailDrawerBody = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userDetail } = useAppSelector((state) => getUserDetail(state));
    const userCountryCode = useAppSelector((state) => getUserCountryTag(state));

    const handleNavigate = () => {
        navigate("/admin/wallets");
    };

    return (
        <>
            <div className='admin-account-details-drawer-container'>
                {userDetail?.status === "unlinked" && (
                    <Alert
                        color='error'
                        message={t("Users.user-not-assigned")}
                        buttons={{
                            className: "white-space-nowrap",
                            color: "error",
                            icon: "arrow-top-right-on-square",
                            label: t("CommonUse.link-account"),
                            position: "right",
                            onClick: handleNavigate,
                        }}
                    />
                )}
                <AdminAccountDetailDrawerSectionsPhone />
                <AdminAccountDrawerPersonalInfo />
                <AdminAccountDetailDrawerSectionsRole />
                {userCountryCode !== "TGO" && <AdminUsersDetailDrawerSectionCollector />}
                <AdminAccountDetailDrawerDisableUser />
                {userDetail?.status !== "archived" && <AdminAccountDetailDrawerArchiveUser />}
            </div>
        </>
    );
};
