import { EditTaskInputCell } from "../InputCell";
import { CreateTaskItemContext } from "src/shared/context/createTaskItem";

import { useContext } from "react";

import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    taskId?: number;
};

export function TasksItemCreateFieldExternalName({ taskId }: Props) {
    const { loading, taskItem, setTaskItem } = useContext(CreateTaskItemContext);

    const externalReference = taskItem?.externalReference;
    const externalFullname = taskItem?.externalFullname;

    const handleUpdateExternalFullname = (newExternalFullname: string) => {
        if (taskId && taskItem && newExternalFullname !== taskItem.externalFullname) {
            setTaskItem({ ...taskItem, externalFullname: newExternalFullname });
        }
    };

    return (
        <EditTaskInputCell
            onValidate={handleUpdateExternalFullname}
            editOpen={!!externalReference && !externalFullname}
            initialValue={taskItem?.externalFullname ?? ""}
            disabled={loading}
            externalReference={{ isExternalReference: true, isBankWire: taskItem?.typeSlug === "bank-transfer" }}
        >
            <Typography className='create-task-table-cell-content' message={taskItem?.externalFullname} />
        </EditTaskInputCell>
    );
}
