import { roles } from "../../const";
import { CreateUserContext } from "src/shared/context/createUser";
import { UserModel } from "src/shared/models/User";
import { CompanyRole } from "src/shared/models/UserCompany";

import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { fetchAdminWallets } from "src/modules/admin/adminWallets/thunkActions";
import { getUserDetail } from "src/modules/admin/userDetail/selectors";
import { getAuthUser } from "src/modules/auth/selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Radio } from "src/shared/atoms/Radio/Radio";
import { Switch } from "src/shared/atoms/Switch/Switch";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "../styles.scss";

type RolesKeys =
    | "addAccessType"
    | "accessType"
    | "addWaveCollect"
    | "customWavePhone"
    | "waveCountryCode"
    | "collectorInfo"
    | "waveWalletId"
    | "wavePhone";
export type RolesForm = Pick<UserModel, RolesKeys>;
export type RolesParams = Omit<RolesForm, "collectorInfo">;

const roleInitialValues = {
    addAccessType: false,
    accessType: undefined,
};

type AdminAccountsFormsRoleProps = {
    attachToWallet?: boolean;
    detail?: UserModel | null;
    editMode?: boolean;
    onChange: (values: RolesParams) => void;
    onValidated: (isValidated: boolean) => void;
};

export const AdminAccountsFormsRole = ({
    attachToWallet,
    detail,
    editMode,
    onChange,
    onValidated,
}: AdminAccountsFormsRoleProps) => {
    const { t } = useTranslation();
    const { userValues } = useContext(CreateUserContext);
    const dispatch = useAppDispatch();

    const [formValues, setFormValues] = useState<RolesForm>({ ...detail } || roleInitialValues);
    const [addAccessType, setAddAccessType] = useState(detail?.addAccessType ?? false);
    const [accessType, setAccessType] = useState(detail?.accessType);

    const user = useAppSelector(getAuthUser);
    const { userDetail } = useAppSelector(getUserDetail);

    useEffect(() => {
        dispatch(
            fetchAdminWallets({
                page: -1,
                archived: false,
            })
        );
    }, [dispatch]);

    useEffect(() => {
        onChange(formValues);
        onValidated(checkFormValidation());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues, userValues?.waveWalletId]);

    const handleChangeRole = (role: CompanyRole) => () => {
        setAccessType(role);
        setFormValues({ ...formValues, ...userValues, accessType: role });
    };

    const isDisabled = (role: CompanyRole) => {
        if (attachToWallet && accessType === CompanyRole.READ) {
            return true;
        }
        if (user?.role === CompanyRole.ADMIN) {
            return false;
        }

        if (editMode && [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY].includes(userDetail?.accessType as CompanyRole)) {
            return true;
        }

        return [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY].includes(role);
    };

    const checkFormValidation = () => {
        if (addAccessType && !formValues.accessType) {
            return false;
        }

        if (addAccessType === false && !userValues?.waveWalletId) {
            return false;
        }

        return true;
    };

    const handleChangeIsRoleFormOpen = () => {
        setFormValues({
            ...formValues,
            ...userValues,
            addAccessType: !addAccessType,
        });
        setAddAccessType(!addAccessType);
    };

    return (
        <div className='user-drawer-roles-form'>
            <div className='user-roles-title-switch'>
                <Switch
                    isOn={addAccessType}
                    onToggle={handleChangeIsRoleFormOpen}
                    label={t<string>("Users.add-user-role")}
                />
                <Typography className='subtitle' message={t("Users.add-user-role-sub")} />
            </div>
            {addAccessType && (
                <div className='radio-container'>
                    {user?.role !== CompanyRole.ADMIN && (
                        <Alert message={t("Users.only-admin-can-edit-admins")} color='warning' />
                    )}
                    {roles.map((item, index) => (
                        <Radio
                            key={index}
                            checked={formValues.accessType === item.role}
                            disabled={isDisabled(item.role)}
                            onChange={handleChangeRole(item.role)}
                            label={
                                <div className={`radio-roles-select ${isDisabled(item.role) ? "disabled" : ""}`}>
                                    <Typography className='fw-bold' message={item.label} />
                                    <Typography className='sm color-neutral-500' message={item.description} />
                                </div>
                            }
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
