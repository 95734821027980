import { ChangeEvent, useRef } from "react";

import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    label: string;
    file?: File | null;
    loading?: boolean;
    classname?: string;
    handleOnSubmit: (file: File) => void;
    acceptedFiles?: string[];
};

export function FileUploaderButton({ label, acceptedFiles, classname, loading, handleOnSubmit }: Props) {
    const inputRef = useRef<HTMLInputElement>(null);

    const onFileLoaded = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];

        const filesize =  selectedFile ? parseInt((selectedFile.size / 1024 / 1024).toFixed(4)) : 0;
        if (filesize > 5 || filesize === 0) {
            alert("Veuillez selectionner un fichier de moins de 2MB");
        } else if (selectedFile) {
            handleOnSubmit(selectedFile);
            event.target.value = "";
        }
    };

    const handleClickInput = () => {
        if (inputRef.current) {
            inputRef.current?.click();
        }
    };

    return (
        <div className={classname} onClick={handleClickInput}>
            <label className='cursor-pointer' htmlFor='user-photo'>
                <div className='button' data-testid='button-file-uploader'>
                    <Typography message={label} />
                </div>
            </label>
            <input
                disabled={loading}
                ref={inputRef}
                type='file'
                id='file-uploader-button'
                accept={acceptedFiles?.join(",")}
                onChange={onFileLoaded}
                style={{ display: "none" }}
                data-testid='input-file-uploader'
            />
        </div>
    );
}
