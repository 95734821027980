import { preventDefault } from "src/shared/utils/preventDefault";

import { ChangeEvent, DragEvent, SyntheticEvent, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Drawer } from "src/shared/atoms/Drawer/Drawer";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { IconButton } from "src/shared/components/IconButton/IconButton";

import "./styles.scss";

const fileUploaderImage = require("src/shared/images/file-uploading/document.png");

export type FileUploaderDragNDropProps = {
    title?: string;
    file?: File | null;
    loading?: boolean;
    classname?: string;
    disabled?: boolean;
    handleOnSubmit: (file: File) => void;
    handleDelete?: () => void;
    acceptedFiles?: string[];
    preview?: boolean;
};

export function FileUploaderDragnDrop({
    title,
    classname = "",
    file,
    acceptedFiles,
    loading,
    disabled,
    handleOnSubmit,
    handleDelete,
    preview,
}: Readonly<FileUploaderDragNDropProps>) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { t } = useTranslation();

    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [openViewDrawer, setOpenViewDrawer] = useState(false);
    const [viewDrawerImage, setViewDrawerImage] = useState<string | undefined>();
    const [viewDrawerPdf, setViewDrawerPdf] = useState<string | undefined>();
    const [viewDrawerName, setViewDrawerName] = useState<string | undefined>();

    const onFileLoaded = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const files = event.target.files;

        if (files?.length) {
            const selectedFile = files[0];
            const filesize = parseInt((selectedFile?.size / 1024 / 1024).toFixed(4));
            const isValid = acceptedFiles
                ? acceptedFiles.some((acceptedFile) => selectedFile.type.includes(acceptedFile.replace("*", "")))
                : true;

            if (filesize > 5) {
                alert(t("Profile.picture-size-limit"));
                return;
            } else if (!isValid) {
                alert(t("AdminDocumentsFileUploader.le-format-de-fichier-n-est-pas-accepte"));
                return;
            } else {
                handleOnSubmit(selectedFile);
            }
        }
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = "copy";
        }
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            const file = files[0];
            onFileLoaded({
                target: { files: [file] },
                preventDefault: event.preventDefault.bind(event),
            } as unknown as ChangeEvent<HTMLInputElement>);
        }
    };

    const handleClickInput = (event: SyntheticEvent) => {
        preventDefault(event);
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const isDisabled = loading || disabled;

    useEffect(() => {
        if (file && preview) {
            setPreviewUrl(URL.createObjectURL(file));
        }
    }, [file, preview]);

    const handleOpenViewDrawer = (url: string) => () => {
        const name = url.split("/")[url.split("/").length - 1];

        setViewDrawerName(name);
        setViewDrawerPdf(url);
        setOpenViewDrawer(true);
    };

    const handleCloseViewDrawer = () => {
        setViewDrawerImage(undefined);
        setViewDrawerPdf(undefined);
        setOpenViewDrawer(false);
    };

    return (
        <div className={classname + "file-uploader-drag-n-drop"}>
            {title && <Typography className='fw-bold mt-2 color-neutral-900' message={title} />}
            {file ? (
                <div className='admin-documents-file'>
                    <div className='d-flex align-items-center'>
                        <div className='file-icon-container'>
                            <Avatar color='lilas-900' backgroundColor='lilas-50' icon='document' size='sm' />
                        </div>
                        <Typography className='ml-2 fw-bold color-neutral-900' message={file.name} />
                    </div>
                    <div className='buttons-container'>
                        {preview && previewUrl && (
                            <IconButton
                                iconProps={{ name: "eye", size: "sm" }}
                                color='grey-dark'
                                testId='preview-button'
                                handleOnClick={handleOpenViewDrawer(previewUrl)}
                            />
                        )}
                        {handleDelete && (
                            <IconButton
                                iconProps={{ name: "thrash", className: "color-error-500", size: "sm" }}
                                handleOnClick={handleDelete}
                                disabled={disabled}
                                color='red'
                                testId='delete-button'
                            />
                        )}
                    </div>
                </div>
            ) : (
                <div
                    onDragOver={isDisabled ? undefined : handleDragOver}
                    onDrop={isDisabled ? undefined : handleDrop}
                    className={`generic-file-uploader my-2 ${isDisabled ? "disabled" : "active"}`}
                    data-testid='drop-file-uploader'
                >
                    <label className='cursor-pointer w-100 p-3' htmlFor='file-uploader-drag-n-drop'>
                        <div
                            onClick={isDisabled ? () => {} : handleClickInput}
                            onKeyDown={() => {}}
                            data-testid='button-file-uploader'
                            className='d-flex flex-column align-items-center'
                        >
                            <div className='d-flex w-100 justify-content-center'>
                                {loading ? (
                                    <div className='file-upload pb-2 px-3'>
                                        <Spinner size='sm' />
                                    </div>
                                ) : (
                                    <img className='image' src={fileUploaderImage} alt='file uploader logo' />
                                )}
                            </div>
                            <div className='d-flex pt-2'>
                                <Typography
                                    className='fw-bold color-lilas-900'
                                    message={t("FileUploader.telechargez-un-fichier")}
                                />
                                &nbsp;
                                <Typography
                                    className='fw-bold mr-1'
                                    message={t("FileUploader.ou-glissez-puis-deposez")}
                                />
                            </div>
                        </div>
                    </label>
                    <input
                        accept={acceptedFiles?.join(",")}
                        ref={inputRef}
                        disabled={isDisabled}
                        type='file'
                        id='file-uploader-drag-n-drop'
                        onChange={isDisabled ? undefined : onFileLoaded}
                        style={{ display: "none" }}
                        data-testid='input-file-uploader'
                    />
                </div>
            )}
            <Drawer
                isOpen={openViewDrawer}
                onClose={handleCloseViewDrawer}
                header={{ image: "document", title: viewDrawerName ?? "" }}
                body={
                    <>
                        {viewDrawerImage && (
                            <div className='preview-img' style={{ backgroundImage: `url(${viewDrawerImage})` }} />
                        )}
                        {viewDrawerPdf && <iframe className='iframe-preview' src={viewDrawerPdf} />}
                    </>
                }
            />
        </div>
    );
}
