import { TransactionSlug } from "src/services/transactions/types";
import { TransactionContext } from "src/shared/context/transaction";
import { computeTimestampToString } from "src/shared/utils/formatDate";
import { formatMoneyToString } from "src/shared/utils/formatMoney";
import { formatPhone } from "src/shared/utils/formatPhone";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { TransactionServiceImage } from "src/components/History/Info/ServiceImage";
import { StatusIcon } from "src/shared/atoms/Icons/StatusIcons";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";

import "./styles.scss";

type GetBeneficiaryProps = {
    transactionTypeSlug: TransactionSlug;
    externalReference: string;
};
const getBeneficiary = ({ transactionTypeSlug, externalReference }: GetBeneficiaryProps) => {
    switch (transactionTypeSlug) {
        case "bank-disposal":
        case "momo-deposit":
        case "merchant-payment":
        case "transfer":
        case "wave-transfer":
        case "disposal":
        case "airtime":
        case "international-transfer":
            return `${formatPhone(externalReference)}`;
        default:
            return externalReference;
    }
};

export function HistoryWalletDrawerDetails() {
    const transaction = useContext(TransactionContext);
    const { t } = useTranslation();

    const {
        uuid,
        amount,
        createdAt,
        TransactionType,
        payerReference,
        invoiceReference,
        externalReference,
        externalFullname,
        fees,
        Service,
        status,
        partnerComment,
        collectorInfo,
    } = transaction;

    const billFields = {
        payerReference: payerReference ?? "-",
        invoiceReference: invoiceReference ?? "-",
        details: partnerComment ?? "-",
    };

    const receiverName = (() => {
        const nameParts = [];

        if (collectorInfo?.fullname) {
            nameParts.push(collectorInfo.fullname);
        }

        if (externalFullname !== null && externalFullname !== undefined && externalFullname !== "") {
            nameParts.push(externalFullname);
        }

        return nameParts.join(" / ");
    })();

    return (
        <div className='history-wallet-drawer-details'>
            <Accordion preSelectedOpen noHeaderPadding title={t("CommonUse.operation-details")} color='white'>
                <div className='history-wallet-drawer-details-container'>
                    <div className='history-wallet-drawer-details-item'>
                        <Typography
                            className='text-muted fw-bold color-neutral-500'
                            message={t("Transactions.operation-on")}
                        />
                        {transaction.wallet && (
                            <Typography
                                className='fw-bold'
                                message={`${transaction.wallet.label ? transaction.wallet.label + " / " : ""}${
                                    transaction.wallet.reference ? transaction.wallet.reference : ""
                                }`}
                            />
                        )}
                    </div>
                    <div className='history-wallet-drawer-details-item'>
                        <Typography
                            className='text-muted fw-bold color-neutral-500'
                            message={t("Transactions.status")}
                        />
                        <div className='status-icon-container'>
                            <StatusIcon status={transaction?.isArchived ? "archived" : status} withMessage withColor />
                        </div>
                    </div>
                    <div className='history-wallet-drawer-details-item'>
                        <Typography className='text-muted fw-bold color-neutral-500' message={t("CommonUse.amount")} />
                        <Typography
                            className='fw-bold '
                            message={amount ? `${formatMoneyToString({ amount })}` : "-"}
                        />
                    </div>
                    <div className='history-wallet-drawer-details-item'>
                        <Typography className='text-muted fw-bold color-neutral-500' message={t("Transactions.fees")} />
                        <Typography
                            className={`fw-bold ${!!fees && status !== "archived" ? "color-error-500 " : ""}`}
                            message={status === "archived" ? "-" : fees ? `${fees} FCFA` : "Gratuit"}
                        />
                    </div>
                    {TransactionType.slug === "bill-payment" || TransactionType.slug === "bill-repayment"
                        ? Object.entries(billFields).map(([name, value]) => (
                              <div key={`drawer-details-${name}`} className='history-wallet-drawer-details-item'>
                                  <Typography
                                      className='text-muted fw-bold color-neutral-500'
                                      message={t(`Transactions.${name}`)}
                                  />
                                  <Typography className='fw-bold' message={value ?? "-"} withPlaceholder />
                              </div>
                          ))
                        : null}
                    <div className='history-wallet-drawer-details-item'>
                        <Typography
                            className='text-muted fw-bold color-neutral-500'
                            message={t("Transactions.receiver")}
                        />
                        <Typography
                            className='fw-bold'
                            message={getBeneficiary({
                                transactionTypeSlug: TransactionType.slug,
                                externalReference: externalReference ?? "",
                            })}
                            withPlaceholder
                        />
                    </div>
                    <div className='history-wallet-drawer-details-item'>
                        <Typography
                            className='text-muted fw-bold color-neutral-500'
                            message={t("Transactions.receiver-name")}
                        />
                        <Typography className='fw-bold' message={receiverName} withPlaceholder />
                    </div>
                    <div className='history-wallet-drawer-details-item'>
                        <Typography
                            className='text-muted fw-bold color-neutral-500'
                            message={t("Users.user-reference")}
                        />
                        <Typography
                            className=' fw-bold '
                            message={collectorInfo?.customReference ?? "-"}
                            withPlaceholder
                        />
                    </div>
                    <div className='history-wallet-drawer-details-item'>
                        <Typography
                            className='text-muted fw-bold color-neutral-500'
                            message={t("Transactions.service")}
                        />
                        <div className='transaction-item'>
                            <TransactionServiceImage serviceSlug={Service.slug} />
                            <Typography className='fw-bold ' message={Service.title} />
                        </div>
                    </div>
                    <div className='history-wallet-drawer-details-item'>
                        <Typography className='text-muted fw-bold color-neutral-500' message={t("Transactions.date")} />
                        <Typography
                            className='fw-bold '
                            message={computeTimestampToString(createdAt, "DD/MM/YY - HH:mm")}
                        />
                    </div>
                    <div className='history-wallet-drawer-details-item'>
                        <Typography
                            className='text-muted fw-bold color-neutral-500'
                            message={t("CommonUse.reference")}
                        />
                        <Typography className='fw-bold  color-lilas-900' message={uuid} />
                    </div>
                </div>
            </Accordion>
        </div>
    );
}
