import { CountryCode } from "libphonenumber-js";

export interface UserCompanyModel {
    id: number;
    userId: number;
    companyId: number;
    role: CompanyRole;
    isActive: boolean;
    createdAt: string;
    updatedAt: string;
    archivedAt: string | null;
    customReference: string | null;
}

export enum CompanyRole {
    ADMIN = "ADMIN",
    ADMIN_ONLY = "ADMIN-ONLY",
    USER = "USER",
    READ = "READ",
    COLLECTOR = "COLLECTOR",
}

export enum CompanyRoleLabel {
    ADMIN = "Roles.admin",
    "ADMIN-ONLY" = "Roles.admin-only",
    USER = "Roles.user",
    READ = "Roles.read",
    COLLECTOR = "Roles.collector",
}

export enum CompanyKybLevel {
    USER = "user",
    KYB_0 = "kyb0",
    KYB_1 = "kyb1",
    KYB_2 = "kyb2",
}
export enum CompanyStatus {
    BLOCK_0 = "block0",
    BLOCK_1 = "block1",
    BLOCK_2 = "block2",
}

export type CountriesTag = "CIV" | "SEN" | "BEN" | "TGO";

export const countryCodeCheck: { [key: string]: CountriesTag } = {
    "00225": "CIV",
    "00221": "SEN",
    "00229": "BEN",
    "00228": "TGO",
};
export const phoneCountryCodes: { [key: string]: CountryCode } = {
    "00225": "CI",
    "00221": "SN",
    "00229": "BJ",
    "00228": "TG",
};
