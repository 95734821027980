import { LoginCodeValidation } from "./CodeValidation";
import { EmailValidationPanel } from "./EmailValidation/Panel";
import { LoginNewPassword } from "./NewPassword";
import { LoginDisplays } from "./Panel";
import { login, requestForgotPassword } from "src/services/auth/operations";
import { LoginContext } from "src/shared/context/login";
import { setInStorage } from "src/shared/utils/storage";

import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUserRemainingConnection } from "src/modules/auth/selectors";
import { failedLogin } from "src/modules/auth/slice";
import { isAuthLoginResponse } from "src/modules/auth/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

const TIMER_DURATION_IN_SECONDES = 15;
let interval: ReturnType<typeof setInterval> | undefined;

type Props = {
    formToDisplay: LoginDisplays;
    handleSetFormToDisplay: (switchName: LoginDisplays) => () => void;
};

export function LoginPasswordReset({ formToDisplay, handleSetFormToDisplay }: Readonly<Props>) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { identifier, setIdentifier, password, pinCode } = useContext(LoginContext);

    const [seconds, setSeconds] = useState(TIMER_DURATION_IN_SECONDES);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const [passwordError, setPasswordError] = useState<string | undefined>();
    const [isIdentifierButtonDisabled, setIsIdentifierButtonDisabled] = useState(false);

    const remainingConnection = useAppSelector((state) => getAuthUserRemainingConnection(state) ?? "");

    useEffect(() => {
        setIsIdentifierButtonDisabled(!identifier);
    }, [identifier]);

    useEffect(() => {
        if (formToDisplay === "code" && seconds === TIMER_DURATION_IN_SECONDES) {
            interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds((seconds) => (seconds > 0 ? seconds - 1 : seconds));
                }
            }, 1000);
        }
    }, [formToDisplay, seconds]);

    useEffect(() => {
        return () => interval && clearInterval(interval);
    }, []);

    const handleSetIdentifier = (newValue: string) => {
        setIdentifier(newValue ? newValue : "");
    };

    const handlePasswordForgot = () => {
        if (!identifier) {
            setError(t("CommonUse.please-fill-field").toString());
        } else {
            setLoading(true);
            setError(undefined);
            requestForgotPassword({ identifier })
                .then(() => handleSetFormToDisplay("code")())
                .catch((error) => error && setError(error.message))
                .finally(() => setLoading(false));
        }
    };

    const handleConnection = async () => {
        setLoading(true);
        setPasswordError(undefined);
        login({ identifier, password })
            .then((res) => {
                if (isAuthLoginResponse(res)) {
                    if (res.user.tfaActive) {
                        handleSetFormToDisplay("twoFactor")();
                        return;
                    }
                    const { id, userId, createdAt } = res;
                    setInStorage("accessToken", { id, userId, createdAt });
                    handleSetFormToDisplay("newPassword")();
                } else {
                    handleSetFormToDisplay("twoFactor")();
                }
            })
            .catch((error) => {
                dispatch(
                    failedLogin({
                        error: error.message,
                        remainingConnection: error?.data ?? undefined,
                    })
                );
                setPasswordError(error.message);
            })
            .finally(() => setLoading(false));
    };

    const handleSendAnotherCode = () => {
        handlePasswordForgot();
        clearInterval(interval);
        setSeconds(TIMER_DURATION_IN_SECONDES);
    };

    return (
        <>
            {formToDisplay === "newPassword" ? (
                <LoginNewPassword handleSetFormToDisplay={handleSetFormToDisplay} />
            ) : formToDisplay === "emailVerification" ? (
                <EmailValidationPanel />
            ) : formToDisplay === "forgot" ? (
                <div className='forgot-password-form'>
                    <img
                        src={require("src/shared/images/logo/julaya_pro.png")}
                        alt='julaya logo'
                        className='julaya-logo'
                    />
                    <div className='title-part'>
                        <Typography
                            variant='h1'
                            message={t("LoginPasswordReset.reinitialiser-votre-mot-de-passe")}
                            className='fw-bold'
                        />
                        <Typography
                            message={t("LoginPasswordReset.fill-id-to-receive-code")}
                            className='color-neutral-500 fw-bold'
                        />
                    </div>
                    <Input
                        placeholder={t("LoginPasswordReset.n-de-telephone-ou-identifiant")}
                        type='classic'
                        onChange={handleSetIdentifier}
                        value={identifier}
                        variant={error ? "error" : "primary"}
                        underMessage={error ? t<string>(error) : ""}
                        testId='forgot-password-identifier-input'
                    />
                    <div className='button-part'>
                        <Button
                            disabled={loading}
                            variant='tertiary'
                            className='w-50'
                            onClick={handleSetFormToDisplay("login")}
                            label={t("CommonUse.cancel")}
                        />
                        <Button
                            disabled={loading || isIdentifierButtonDisabled}
                            className='w-50'
                            onClick={handlePasswordForgot}
                            label={t("CommonUse.validate")}
                            testId='forgot-password-continue-button'
                        />
                    </div>
                </div>
            ) : (
                <>
                    <Typography
                        variant='h3'
                        message={t("LoginPasswordReset.vous-avez-recu-un-code-temporaire")}
                        className='fw-bold mb-1'
                    />
                    <Typography
                        message={t("LoginPasswordReset.consult-your-device")}
                        className='color-neutral-500 fw-bold'
                    />
                    <LoginCodeValidation />
                    {passwordError ? (
                        <div className='d-flex align-items-center' data-testid='code-validation-error'>
                            <Icon name='x-circle-filled' className='mr-2' color='error' />
                            <Typography
                                className='color-error-500'
                                message={t<string>(passwordError, { count: remainingConnection })}
                            />
                        </div>
                    ) : null}
                    <div className='d-flex mt-2'>
                        <Button
                            disabled={loading}
                            variant='tertiary'
                            className='w-50 mr-2'
                            onClick={handleSetFormToDisplay("login")}
                            label={t("CommonUse.cancel")}
                        />
                        <Button
                            disabled={loading || pinCode.length < 6}
                            className='w-50'
                            onClick={handleConnection}
                            label={t("CommonUse.validate")}
                            testId='code-validation-continue-button'
                        />
                    </div>
                    <div className='login-code-validation-sentence'>
                        <Typography className='color-neutral-500 mr-1' message={t("LoginPasswordReset.no-code")} />
                        {seconds > 0 && (
                            <Typography
                                className='color-lilas-900 underline'
                                message={t("LoginPasswordReset.reset-code-timer", { seconds })}
                            />
                        )}
                        {seconds === 0 && !loading && (
                            <div onClick={handleSendAnotherCode}>
                                <Typography
                                    className='color-lilas-900 underline cursor-pointer'
                                    message={t("LoginPasswordReset.reset-code")}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
}
