import { UserCreateDrawerContainer } from "../../Users/CreateDrawer/Container";
import { useNavigate } from "react-router-dom";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { UserModel } from "src/shared/models/User";
import { AdminWalletModel } from "src/shared/models/Wallet";
import { validateField } from "src/shared/utils/validateField";

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { clearAdminWalletIntegration } from "src/modules/admin/adminWallets/slice";
import { handleCreateAdminWallet, handleUpdateAdminWallet } from "src/modules/admin/adminWallets/thunkActions";
import { getCreatedUser } from "src/modules/admin/userDetail/selectors";
import { getAdminAccountsList } from "src/modules/admin/usersList/selectors";
import { fetchUsersList } from "src/modules/admin/usersList/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { UserSelectionDrawer } from "src/components/UserSelectionDrawer/Drawer";
import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { NumberInput } from "src/shared/atoms/Inputs/Number/Number";
import { Switch } from "src/shared/atoms/Switch/Switch";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { StepAccordion } from "src/shared/components/Accordion/StepAccordion/StepAccordion";
import { Signataire } from "src/shared/components/Signataire/Signataire";

import "./styles.scss";

const filterWalletUsers = ({ wallet }: { wallet?: AdminWalletModel }) => {
    if (wallet) {
        return wallet.users.reduce<{ normalUsers: UserModel[]; superUsers: UserModel[]; groupUsers: UserModel[][] }>(
            (res, user) => {
                if (user.isSuperSignature) {
                    res.superUsers.push(user);
                    return res;
                }

                if (!wallet.isOrderedSignatures) {
                    res.normalUsers.push(user);
                    return res;
                }

                if (!user.order) {
                    res.groupUsers[0] = [...res.groupUsers[0], user];
                } else {
                    res.groupUsers[user.order - 1] = [...(res.groupUsers[user.order - 1] ?? []), user];
                }
                return res;
            },
            {
                normalUsers: [],
                superUsers: [],
                groupUsers: [[]],
            }
        );
    }

    return {
        normalUsers: [],
        superUsers: [],
        groupUsers: [[]],
    };
};

function computeArrayToMatchLenght<T, K>(data: T[], length: number, defaultValue: K) {
    return Array.from({ length }, (_, index) => (data?.[index] ? data[index] : defaultValue));
}

export interface userToRespond {
    id: number;
    order?: number;
    isSuperSignature?: boolean;
}

type Props = {
    wallet?: AdminWalletModel;
};

export const AdminWalletForm = ({ wallet }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { usersList } = useAppSelector((state) => getAdminAccountsList(state));
    const {
        createdUser,
        loading: createUserLoading,
        error: createUserError,
    } = useAppSelector((state) => getCreatedUser(state));

    const [passwordValidationError, setPasswordValidationError] = useState(null);
    const [accountName, setAccountName] = useState("");
    const [signaturesNumber, setSignaturesNumber] = useState(1);
    const [orderedSignatures, setOrderedSignatures] = useState(true);

    const [groupedUsers, setGroupedUsers] = useState<UserModel[][]>([]);
    const [selectedUsers, setSelectedUsers] = useState<UserModel[]>([]);
    const [superSignatories, setSuperSignatories] = useState<UserModel[]>([]);

    const [isOpenCreateDrawer, setIsOpenCreateDrawer] = useState<boolean>(false);
    const [isOpenSuperSignataireSelectionDrawer, setIsOpenSuperSignataireSelectionDrawer] = useState(false);
    const [groupIndexOfCreatedUser, setGroupIndexOfCreatedUser] = useState<number>(-1);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);
    const [step, setStep] = useState(0);
    const [error, setError] = useState("");
    const dispatch = useAppDispatch();

    const [isOpenUserSelectionDrawer, setIsOpenUserSelectionDrawer] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<number>();

    const [selectedCurrentIndex, setSelectedCurrentIndex] = useState<number | null>(null);

    const { normalUsers, superUsers } = useMemo(() => filterWalletUsers({ wallet }), [wallet]);

    const handleCloseCreateDrawer = () => {
        setIsOpenCreateDrawer(false);
    };

    const handleOpenCreateDrawer = (value: number | null) => () => {
        if (isOpenUserSelectionDrawer) {
            setIsOpenUserSelectionDrawer(false);
        }
        if (isOpenSuperSignataireSelectionDrawer) {
            setIsOpenSuperSignataireSelectionDrawer(false);
        }

        setIsOpenCreateDrawer(true);
        if (value !== null) {
            setGroupIndexOfCreatedUser(value);
        }
    };

    const handleSetSignaturesNumber = (newValue: number) => {
        setSignaturesNumber(newValue < 1 ? 1 : newValue);
    };

    const userOptions: UserModel[] = usersList.map((user) => ({
        ...user,
        label: `${user.firstname} ${user.lastname}`,
    }));

    useEffect(() => {
        fetchWalletUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (accountName.length > 0) {
            const { isValid, errorKey } = validateField("text", accountName);
            setError(!isValid && errorKey ? t<string>(errorKey) : "");
        } else {
            setError("");
        }
    }, [accountName]);

    useEffect(() => {
        if (!wallet) {
            return;
        }

        setSignaturesNumber(wallet.nbSignatures);
        setAccountName(wallet.label);
        setOrderedSignatures(wallet.isOrderedSignatures);
    }, [wallet]);

    useEffect(() => {
        setSelectedUsers(normalUsers);
        setSuperSignatories(superUsers);

        if (orderedSignatures) {
            setGroupedUsers((prevGroups) => {
                if (prevGroups.length === signaturesNumber) {
                    return prevGroups;
                }

                if (signaturesNumber < prevGroups.length) {
                    return prevGroups.slice(0, signaturesNumber);
                }

                return [
                    ...prevGroups,
                    ...Array.from({ length: signaturesNumber - prevGroups.length }, () => [] as UserModel[]),
                ];
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signaturesNumber, orderedSignatures]);

    useEffect(() => {
        if (createUserLoading === false && !createUserError && createdUser) {
            if (groupIndexOfCreatedUser > -1) {
                addUserToGroup(createdUser, groupIndexOfCreatedUser)();
            } else {
                addSuperSignatory(createdUser)();
            }
            fetchWalletUsers();
            setGroupIndexOfCreatedUser(-1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createUserLoading]);

    const fetchWalletUsers = () => {
        dispatch(
            fetchUsersList({
                archived: false,
                page: -1,
                query: "",
                role: ["USER", "ADMIN"],
            })
        );
    };

    const sendAccount = () => {
        setOpenPasswordModal(true);
    };

    const isUserInAnyGroup = (user: UserModel) => {
        const isInOrderedGroup = orderedSignatures && groupedUsers.some((group) => group.some((u) => u.id === user.id));
        const isInSelectedGroup = !orderedSignatures && selectedUsers.some((u) => u.id === user.id);
        const isInSuperSignatoryGroup = superSignatories.some((u) => u.id === user.id);

        return isInOrderedGroup || isInSelectedGroup || isInSuperSignatoryGroup;
    };
    const availableAccountsOptions: UserModel[] = userOptions.filter((user) => !isUserInAnyGroup(user));

    const addUserToGroup = (user: UserModel, groupIndex?: number) => () => {
        if (!isUserInAnyGroup(user)) {
            if (orderedSignatures) {
                if (groupIndex !== undefined) {
                    const newGroupedUsers = groupedUsers.map((group, index) =>
                        index === groupIndex ? [...group, user] : group
                    );
                    setGroupedUsers(newGroupedUsers);
                }
            } else {
                setSelectedUsers([...selectedUsers, user]);
            }
            if (superSignatories.some((u) => u.id === user.id)) {
                removeSuperSignatory(superSignatories.findIndex((u) => u.id === user.id))();
            }
        }
    };

    const addSuperSignatory = (user: UserModel) => () => {
        if (!isUserInAnyGroup(user)) {
            setSuperSignatories([...superSignatories, user]);
            if (orderedSignatures) {
                const newGroupedUsers = groupedUsers.map((group) => group.filter((u) => u.id !== user.id));
                setGroupedUsers(newGroupedUsers);
            } else {
                const newSelectedUsers = selectedUsers.filter((u) => u.id !== user.id);
                setSelectedUsers(newSelectedUsers);
            }
        }
    };

    const removeSuperSignatory = (userIndex: number) => () => {
        const newSuperSignatories = superSignatories.filter((_, i) => i !== userIndex);
        setSuperSignatories(newSuperSignatories);
    };

    const removeUserFromGroup = (userIndex: number, groupIndex?: number) => {
        if (orderedSignatures) {
            if (groupIndex !== undefined) {
                const newGroupedUsers = groupedUsers.map((group, index) =>
                    index === groupIndex ? group.filter((_, i) => i !== userIndex) : group
                );
                setGroupedUsers(newGroupedUsers);
            }
        } else {
            const newSelectedUsers = selectedUsers.filter((_, i) => i !== userIndex);
            setSelectedUsers(newSelectedUsers);
        }
    };

    const generateUsersToRespond = () => {
        const users: userToRespond[] = [];

        if (orderedSignatures) {
            groupedUsers.forEach((group, groupIndex) => {
                group.forEach((user) => {
                    users.push({ id: user.id, order: groupIndex + 1 });
                });
            });
        } else {
            selectedUsers.forEach((user) => {
                users.push({ id: user.id });
            });
        }

        superSignatories.forEach((user) => {
            users.push({ id: user.id, isSuperSignature: true });
        });

        return users;
    };

    const checkAccordionOpening = () => {
        const isValidated = Boolean(accountName.length);
        if (!isValidated) {
            setError(t<string>("Wallets.required-field"));
        } else {
            setError("");
        }
    };

    const handleTogglePasswordModal = () => {
        setOpenPasswordModal(!openPasswordModal);
        dispatch(clearAdminWalletIntegration());
    };

    const handleOnSuccess = () => {
        if (wallet) {
            navigate(`/admin/wallets`, { state: { open: wallet.id } });
        } else {
            navigate("/admin/wallets");
        }
    };

    const handleSubmitWallet = async ({ password, twoFactor }: { password: string; twoFactor: string | null }) => {
        const payload = {
            isOrderedSignatures: orderedSignatures,
            isPopulated: true,
            label: accountName,
            nbSignatures: signaturesNumber,
            password,
            otp: twoFactor,
            users: generateUsersToRespond(),
        };
        setPasswordValidationError(null);

        if (wallet) {
            return dispatch(handleUpdateAdminWallet({ walletId: wallet?.id, params: payload })).unwrap();
        } else {
            return dispatch(handleCreateAdminWallet(payload)).unwrap();
        }
    };

    const handleOpenFirstStep = (isOpened: boolean) => {
        if (isOpened) {
            setStep(0);
        } else {
            checkAccordionOpening();
        }
    };

    const computedSelectedUsers = useMemo(() => {
        return selectedUsers.length > signaturesNumber
            ? selectedUsers
            : computeArrayToMatchLenght(selectedUsers, signaturesNumber, {} as UserModel);
    }, [selectedUsers, signaturesNumber]);

    const handleUserSelectionDrawer = (group?: number) => () => {
        setSelectedCurrentIndex(group ?? null);
        setSelectedGroup(group);
        handleOpenUserSelectionDrawer();
    };

    const handleOpenUserSelectionDrawer = () => {
        setIsOpenUserSelectionDrawer(!isOpenUserSelectionDrawer);
    };

    const handleSelectedUser = (user: UserModel) => {
        addUserToGroup(user, selectedGroup)();
        handleOpenUserSelectionDrawer();
    };

    const handleOpenSuperSignataireSelectionDrawer = () => {
        setSelectedCurrentIndex(null);
        setIsOpenSuperSignataireSelectionDrawer(!isOpenSuperSignataireSelectionDrawer);
    };

    const handleSelectedSuperSignataire = (user: UserModel) => {
        addSuperSignatory(user)();
        handleOpenSuperSignataireSelectionDrawer();
    };

    useEffect(() => {
        const handleGlobalEnterPress = (event: any) => {
            if (event.key === "Enter") {
                switch (step) {
                    case 0:
                        if (!accountName.length || !!error) {
                            setStep(1);
                        }
                        break;
                    default:
                        break;
                }
            }
        };

        document.addEventListener("keydown", handleGlobalEnterPress);

        return () => {
            document.removeEventListener("keydown", handleGlobalEnterPress);
        };
    }, [step]);

    const renderToolTipSentence = (groupIndex: number) => {
        if (groupIndex === 0) {
            return t("Wallets.sign-tooltip-first");
        } else if (groupIndex + 1 === signaturesNumber) {
            return t("Wallets.sign-tooltip-last", { level: groupIndex + 1 });
        } else {
            return t("Wallets.sign-tooltip-middle", { level: groupIndex + 1 });
        }
    };

    const handleSetAccountName = (value: string) => {
        setError("");
        setAccountName(value);

        if (value.trim().length > 0) {
            checkAccordionOpening();
        }
    };

    const handleOnBlur = () => {
        if (accountName.trim().length === 0) {
            setError(t<string>("Wallets.required-field"));
        } else {
            checkAccordionOpening();
        }
    };

    return (
        <>
            <div className='admin-wallet-form-container'>
                <StepAccordion
                    title={t("Expense-policy.general-informations")}
                    step={0}
                    currentStep={step}
                    onOpen={handleOpenFirstStep}
                    footer={
                        <Button
                            className='continue-button'
                            variant='primary'
                            disabled={!accountName.length || !!error}
                            label={t("CommonUse.continue")}
                            onClick={() => setStep(1)}
                        />
                    }
                >
                    <div className='accordion-container first-part'>
                        <div className='form-container'>
                            <Input
                                autoFocus
                                placeholder={t("Wallets.choose-name")}
                                onChange={handleSetAccountName}
                                onBlur={handleOnBlur}
                                value={accountName}
                                label={t<string>("Wallets.name")}
                                underMessage={error}
                                variant={error ? "error" : undefined}
                            />
                        </div>
                    </div>
                </StepAccordion>

                <StepAccordion
                    title={t("Wallets.choose-signataires")}
                    step={1}
                    currentStep={step}
                    footer={
                        <Button variant='primary' label='Valider' className='validation-button' onClick={sendAccount} />
                    }
                >
                    <div className='accordion-container second-part'>
                        <div className='form-container'>
                            <Typography className='color-neutral fw-bold' message={t("Wallets.nb-signatories")} />
                            <NumberInput value={signaturesNumber} setValue={handleSetSignaturesNumber} />
                        </div>

                        <div className='form-container'>
                            <Typography className='color-neutral fw-bold' message={t("Wallets.ordered-signs")} />
                            <Switch
                                label={t("Wallets.order-your-signatories").toString()}
                                isOn={orderedSignatures}
                                onToggle={setOrderedSignatures}
                            />
                        </div>
                        <div className='divider' />
                        <div className='add-signataire-container'>
                            <div className='signataires-title'>
                                <Typography message={t("Tasks.signatories")} className='fw-bold' />
                                <Typography message={t("Wallets.signatures-management-warning")} />
                            </div>
                            {(orderedSignatures ? groupedUsers : [computedSelectedUsers]).map((group, groupIndex) => (
                                <div className='add-signataire-item' key={groupIndex}>
                                    <div className='signature-header'>
                                        <Avatar
                                            icon='arrow-right-circle'
                                            backgroundColor='lilas-50'
                                            color='lilas-900'
                                        />
                                        <div className='title'>
                                            <Typography
                                                message={
                                                    orderedSignatures
                                                        ? `${t("Wallets.sign")} #${groupIndex + 1}`
                                                        : t("Tasks.signatories")
                                                }
                                                className='fw-bold'
                                            />
                                            <ToolTip
                                                position='right'
                                                content={<Typography message={renderToolTipSentence(groupIndex)} />}
                                                activation={true}
                                            >
                                                <Icon name='information-circle' color='neutral-500' />
                                            </ToolTip>
                                        </div>
                                    </div>
                                    <div className='signature-content'>
                                        {group.map((user, index) =>
                                            user?.firstname ? (
                                                <div className='signataire-content-list' key={index}>
                                                    <Signataire
                                                        name={`${user.firstname} ${user.lastname}`}
                                                        picture={user.avatar ? user.avatar : null}
                                                    />
                                                    <div
                                                        className='icon-trash-container'
                                                        onClick={() =>
                                                            removeUserFromGroup(
                                                                index,
                                                                orderedSignatures ? groupIndex : undefined
                                                            )
                                                        }
                                                    >
                                                        <Icon name='thrash' />
                                                    </div>
                                                </div>
                                            ) : null
                                        )}
                                        <Button
                                            variant='tertiary'
                                            className='w-100'
                                            label={t("Wallets.add-signataire")}
                                            icon='user-check'
                                            onClick={handleUserSelectionDrawer(
                                                orderedSignatures ? groupIndex : undefined
                                            )}
                                        />
                                    </div>
                                </div>
                            ))}
                            <div className='signature-sent'>
                                <Avatar icon='check-circle' backgroundColor='success-50' color='success-500' />
                                <Typography message={t("Wallets.send-payment")} className='fw-bold' />
                            </div>
                        </div>

                        <div className='divider' />
                        <div className='add-signataire-item'>
                            <div className='signataires-title'>
                                <Typography message={t("Wallets.super-signataires-title")} className='fw-bold' />
                                <Typography message={t("Wallets.super-signatory-warning")} />
                            </div>
                            <div className='signature-header'>
                                <Avatar icon='arrow-right-circle' backgroundColor='lilas-50' color='lilas-900' />
                                <div className='title'>
                                    <Typography message={t("Wallets.super-signatures")} className='fw-bold' />
                                    <ToolTip
                                        position='right'
                                        content={<Typography message={t("Wallets.people-can-sign-alone")} />}
                                        activation={true}
                                    >
                                        <Icon name='information-circle' color='neutral-500' />
                                    </ToolTip>
                                </div>
                            </div>
                            <div className='signature-content'>
                                {superSignatories.map((user, index) => (
                                    <div className='signataire-content-list' key={index}>
                                        <Signataire
                                            name={`${user.firstname} ${user.lastname}`}
                                            picture={user.avatar ? user.avatar : null}
                                            isSuperSignataire={true}
                                        />
                                        <div className='icon-trash-container' onClick={removeSuperSignatory(index)}>
                                            <Icon name='thrash' />
                                        </div>
                                    </div>
                                ))}
                                <Button
                                    variant='tertiary'
                                    className='w-100'
                                    label={t("Wallets.add-super-signatairre")}
                                    icon='user-circle'
                                    onClick={handleOpenSuperSignataireSelectionDrawer}
                                />
                            </div>

                            <div className='signature-sent'>
                                <Avatar icon='check-circle' backgroundColor='success-50' color='success-500' />
                                <Typography message={t("Wallets.send-payment")} className='fw-bold' />
                            </div>
                        </div>
                    </div>
                </StepAccordion>
            </div>

            <UserCreateDrawerContainer
                attachToWallet
                isOpen={isOpenCreateDrawer}
                onCloseDrawer={handleCloseCreateDrawer}
            />
            <UserSelectionDrawer
                isOpen={isOpenUserSelectionDrawer}
                onClose={handleOpenUserSelectionDrawer}
                handleSelectedUser={handleSelectedUser}
                handleOpenCreateUser={handleOpenCreateDrawer(selectedCurrentIndex)}
                userList={availableAccountsOptions}
            />
            <UserSelectionDrawer
                isOpen={isOpenSuperSignataireSelectionDrawer}
                onClose={handleOpenSuperSignataireSelectionDrawer}
                handleSelectedUser={handleSelectedSuperSignataire}
                handleOpenCreateUser={handleOpenCreateDrawer(selectedCurrentIndex)}
                userList={availableAccountsOptions}
            />
            <PasswordValidationModal
                header={{
                    title: t("CommonUse.add", { entity: t("Wallets.a-wallet") }),
                    icon: "wallet",
                }}
                successContent={{
                    image: require("src/shared/images/user/success.png"),
                    text: t(wallet ? "Wallets.modification-successful" : "Wallets.create-success").toString(),
                }}
                error={passwordValidationError}
                open={openPasswordModal}
                setOpen={handleTogglePasswordModal}
                handleOnSubmit={handleSubmitWallet}
                onSuccess={handleOnSuccess}
            />
        </>
    );
};
